import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../../layout/layout"
import Footer from "../../components/Footer"
import SubpageTitle from "../../components/SubpageTitle"
import Gallery from "../../components/Gallery"
import Navigation from "../../components/Navigation"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const GalleryWrapper = styled.div`
  flex: 1;
  width: 100%;
`

const index = ({
  data: {
    blog: { posts },
  },
}) => {
  return (
    <Layout>
      <Wrapper>
        <Navigation />
        <SubpageTitle
          category="Wyprawy"
          description="Poznaj relacje z wypraw"
        />
        <GalleryWrapper>
          <Gallery isNews={false} posts={posts} />
        </GalleryWrapper>
        <Footer />
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query wyprawy {
    blog {
      posts(where: { kategoria: Wyprawy }, orderBy: data_DESC) {
        id
        title
        kategoria
        opis1
        data
        autor
        zdjecie_glowne {
          url
        }
      }
    }
  }
`

export default index
