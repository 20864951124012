import React from "react"
import styled from "styled-components"

import Container from "../../Container/index"
import GalleryItem from "../GalleryItem"

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;

  ${({ theme }) => theme.mq.lg} {
    justify-content: flex-start;
    flex-direction: row;

    ${({ isNews }) => isNews && `width: fit-content;`};
    ${({ isNews }) => isNews && `flex-direction: column;`};
    ${({ isNews }) => isNews && `justify-content: space-between`};
  }

  & > a {
    display: flex;
    margin: 20px 0px;
    justify-content: center;
    align-items: center;
    max-height: 390px;
    object-fit: cover;

    ${({   theme   }) => theme.mq.md} {
      ${({isNews}) => !isNews && `margin: 20px `};
    }
  }
`

const CategoryGallery = ({ posts, isNews }) => {
  return (
    <Container>
      <Wrapper isNews={isNews}>
        {posts.map(post => (
          <GalleryItem key={post.id} post={post} />
        ))}
      </Wrapper>
    </Container>
  )
}

export default CategoryGallery
