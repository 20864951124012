import React from "react"
import styled from "styled-components"

import Container from "../../Container/index"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
`
const Heading = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.size.sm};

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.lg};
  }
`

const Description = styled.p`
  color: ${({ theme }) => theme.colors.grayishCyan};
  font-size: ${({ theme }) => theme.font.size.xxs};

  ${({ theme }) => theme.mq.md} {
    font-size: 16px;
  }
`

const SubpageTitle = ({ category, description }) => {
  return (
    <Container>
      <Wrapper>
        <Heading>{category}</Heading>
        <Description>{description}</Description>
      </Wrapper>
    </Container>
  )
}

export default SubpageTitle
